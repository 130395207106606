import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Tou() {
  return (
    <Layout>
      <div id="tou">
        <SEO title="Terms of User" />
        <div className="container m-b-lg">
          <div>
            <h2>TERMS OF USE</h2>
            <p>
              This site ("Site") is operated by Congress.Cards. Please read
              these terms of use, which include the Privacy Policy, ("Terms").
              By accessing this Site or using the services offered on the Site,
              including by responding to a registered user’s request to provide
              your contact information, you indicate your agreement to these
              Terms. If you do not agree, please do not use this Site. We may
              modify these Terms (including the Privacy Statement) from time to
              time by displaying updated Terms on the Site. Your continued use
              of the Site after updated Terms are displayed indicates your
              agreement to the modified Terms.
            </p>
            <h4>CONDUCT</h4>
            <p>
              You expressly agree that you will not use the Site or send any
              email containing a congress.cards link:
            </p>
            <ul>
              <li>
                <p>
                  for any unlawful purpose, including posting, storing, sending,
                  or otherwise transmitting information, data or material that
                  is libelous, obscene, threatening, harrassing or defamatory or
                  which infringes the intellectual property rights of any person
                  or which in any way encourages conduct that would be unlawful
                  or inappropriate;
                </p>
              </li>
              <li>
                <p>
                  in connection with any unsolicited bulk or commercial messages
                  (commonly known as "spam"), any advertising or other form of
                  communication that violates these Terms or any law or
                  regulation, or in any manner that would disrupt in any way any
                  internet site or services;
                </p>
              </li>
              <li>
                <p>
                  to initiate or perpetuate any illegal scheme (such as a
                  pyramid scheme);
                </p>
              </li>
              <li>
                <p>
                  to harvest (or engage in any type of systematic retrieval of)
                  e-mail addresses or other identifiers or information or
                  collect e-mail addresses;
                </p>
              </li>
              <li>
                <p>
                  to impersonate any person or entity, falsify any sender
                  address, forge anyone’s identity or perform any other
                  fraudulent activity (such as "phishing");
                </p>
              </li>
              <li>
                <p>
                  to interfere with, or attempt to interfere with, any person’s
                  ability to use the services offered on the Site;
                </p>
              </li>
              <li>
                <p>
                  to transmit or otherwise make available through our Site any
                  computer code, file, program or other element (including any
                  virus, worm, or other harmful malware of any nature) that
                  would interrupt, destroy, interfere with or otherwise cause
                  any other effect that might reasonably be considered
                  undesirable;
                </p>
              </li>
              <li>
                <p>
                  to register or attempt to use the services under the name of
                  another person, allow another person to use your username or
                  password or your Account, use another person’s Account; or
                </p>
              </li>
              <li>
                <p>
                  to attempt to (or actually) circumvent the Site’s security or
                  any means to control access to the Site’s services.
                </p>
              </li>
            </ul>

            <h4>OWNERSHIP</h4>
            <p>
              Unless otherwise noted, all rights, title and interest in all
              information including, but not limited to, screens, texts, user
              names, passwords, web pages, or other materials (collectively
              “Information”) appearing in and on this Site are the exclusive
              property of Congress.Cards. Congress.Cards’s use of such
              information is subject to its Privacy Policy.
            </p>
            <p>
              You acknowledge that all content on or provided through this Site,
              including, but not limited to software, images, illustrations,
              whether tangible or intangible (collectively “Content”), is
              protected by copyrights, trademarks or service marks and other
              intellectual property rights which are owned and controlled by
              Congress.Cards or by third parties that have licensed their
              Content or the right to market and sell their products to
              Congress.Cards. Such Content may not be copied, reproduced,
              republished, uploaded, posted, transmitted, distributed or
              exploited in any way, without proper permission from the Content’s
              owner.
            </p>
            <p>
              "Congress.Cards" and other names, trademarks, service marks, and
              indicia of source ("Congress.Cards Trademarks") are owned by
              Congress.Cards. You may not use the Congress.Cards Trademarks in
              connection with any products or services that do not belong to
              Congress.Cards or in any manner likely to cause confusion about
              the source of any product or service, that implies that
              Congress.Cards endorses anyone else’s product or service, or that
              disparages or discredits Congress.Cards.
            </p>

            <h4>ALLEGED COPYRIGHT INFRINGEMENT</h4>
            <p>
              If you believe that your intellectual property rights have been
              violated by Congress.Cards or by a third party who has provided
              Content on our Site, please provide the following information to
              the Congress.Cards-designated copyright agent listed below.
            </p>
            <p>
              a. A description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </p>
            <p>
              b. A description of where the material that you claim is
              infringing is located on the Site;
            </p>
            <p>
              c. An address, a telephone number, and an e-mail address where
              Congress.Cards can contact you;
            </p>
            <p>
              d. A statement that you have a good-faith belief that the use is
              not authorized by the copyright or other intellectual property
              rights owner, by its agent, or by law;
            </p>
            <p>
              e. A statement by you under penalty of perjury that the
              information in your notice is accurate and that you are the
              copyright or intellectual property owner or are authorized to act
              on the owner’s behalf;
            </p>
            <p>f. Your electronic or physical signature.</p>
            <p>
              Congress.Cards may request additional information before removing
              any infringing material. Congress.Cards may provide the alleged
              infringing party with your e-mail address so that that person can
              respond to your allegations.
            </p>
            <p>
              Pursuant to Title 17, United State code, Section 512(c)(2),
              notifications of claimed copyright infringement should be sent to
              Service Provider at info@congress.cards
            </p>

            <h4>LINKS, FRAMES AND METATAGS</h4>
            <p>
              You may not frame the content of our Site unless you first obtain
              our express prior written consent in each case. You may not use
              metatags or any other "hidden text" that incorporates
              Congress.Cards Trademarks or marks confusingly similar to our
              Congress.Cards Trademarks without our prior written consent.
            </p>
            <h4>LINKS TO THIRD-PARTY WEB SITES</h4>
            <p>
              Our Site may contain links to web sites controlled by third
              parties ("Third-Party Sites"). We are not responsible for
              Third-Party Sites or their content, activities, or privacy
              practices. Any information you share or actions you take on
              Third-Party Sites are governed by those sites’ terms of use and
              privacy statements, which you should review carefully to learn
              about their practices. Including links on our Site does not imply
              our endorsement of Third-Party Sites, their content, or any
              associated organization or activity. We make no representation or
              warranty whatsoever about the nature of Third-Party Sites.
            </p>

            <h4>PURCHASE, PAYMENT TERMS</h4>
            <p>
              Congress.Cards accepts major U.S. credit cards (Visa, MasterCard,
              American Express, and Discover). Billing occurs at the time of or
              shortly after your transaction. You agree that you will only use
              your own credit card or have express permission to use another
              person’s credit card.
            </p>
            <p>
              You agree that you will pay for all products you purchase and that
              Congress.Cards may charge your credit card for any products
              purchased and for any additional amounts (including any taxes and
              late fees, as applicable). You are responsible for the timely
              payment of all fees to Congress.Cards.
            </p>
            <p>
              Your total price will include the price of the product(s) plus any
              applicable sales tax; such sales tax is based on the bill-to
              address and the sales tax rate in effect at the time you purchase
              the product(s).
            </p>

            <h4>PRODUCT FULFILLMENT</h4>
            <p>
              Purchased product(s) are generally shipped in 1-2 business days
              but may from time to time take longer. Items shipped directly to
              recipients with first class stamps will arrive in approximately 3
              to 7 business days from the date of order. Bulk products shipped
              directly to customers (box of cards) will arrive in approximately
              1 to 5 business days, depending on the method of shipping chosen
              (overnight vs. standard).
            </p>

            <h4>DISCLAIMERS</h4>
            <p>
              OUR SITE AND THE SERVICES AVAILABLE THROUGH IT ARE AVAILABLE "AS
              IS" AND "AS AVAILABLE." TO THE MAXIMUM EXTENT PERMITTED UNDER LAW,
              WE DISCLAIM ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING ANY
              WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE
              (EVEN IF THAT PURPOSE HAS BEEN DISCLOSED) AND ANY WARRANTY THAT
              OUR SITE OR SERVICES (1) WILL BE UNINTERRUPTED, FREE FROM ERRORS,
              OR FREE FROM VIRUSES, WORMS, "TROJAN HORSES" OR OTHER MALICIOUS
              CODE OR HARMFUL FEATURES OR (2) WILL MEET YOUR REQUIREMENTS OR BE
              ACCURATE, COMPLETE, TIMELY, RELIABLE, OR NON-INFRINGING. WE
              RESERVE THE RIGHT TO CORRECT ANY ERRORS IN OUR SITE OR SERVICES.
              YOU ARE SOLELY RESPONSIBLE FOR MAKING BACKUP COPIES OF AND
              PRESERVING ALL INFORMATION IN YOUR ADDRESS BOOK. IF YOU RELY ON
              OUR SITE OR SERVICES, YOU DO SO ENTIRELY AT YOUR OWN RISK.
            </p>

            <h4>LIMITATION OF LIABILITY</h4>
            <p>
              UNDER NO CIRCUMSTANCES WILL Congress.Cards (OR ITS MEMBERS,
              DIRECTORS, OFFICERS, EMPLOYEES, SERVICE PROVIDERS, REPRESENTATIVES
              AND AGENTS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR USE OF OUR
              SITE OR SERVICES, INCLUDING ANY (1) ERRORS, OMISSIONS,
              INTERRUPTIONS, OR INACCURACIES IN OUR SITE OR ANY SERVICES, (2)
              FAILURE TO STORE OR PRESERVE INFORMATION IN CONNECTION WITH YOUR
              ADDRESS BOOK, OR (3) DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
              SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES (INCLUDING LOST REVENUES
              OR PROFITS, LOSS OF BUSINESS, OR LOSS OF DATA) ARISING OUT OF YOUR
              USE OR MISUSE OF OUR SITE OR SERVICES, REGARDLESS OF THE THEORY OF
              LIABILITY, WHETHER TORT (INCLUDING NEGLIGENCE), CONTRACT, OR ANY
              OTHER LEGAL OR EQUITABLE THEORY AND EVEN IF WE ARE ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES OR LOSS. SOME STATES DO NOT ALLOW THE
              LIMITATION OF LIABILITY FOR THESE KINDS OF DAMAGES, SO THESE
              LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. YOU RELEASE
              Congress.Cards (AND ITS MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES,
              SERVICE PROVIDERS, REPRESENTATIVES AND AGENTS) FROM ANY CLAIM
              RELATING TO YOUR USE OF OUR SITE OR SERVICES. YOU AGREE THAT THE
              LIABILITY OF Congress.Cards (AND ITS MEMBERS, DIRECTORS, OFFICERS,
              EMPLOYEES, SERVICE PROVIDERS, REPRESENTATIVES, AND AGENTS), IF
              ANY, ARISING OUT OF ANY LEGAL CLAIM ARISING FROM YOUR USE OF OUR
              SITE OR SERVICES WILL NOT EXCEED $1,000.00 IN THE AGGREGATE. YOU
              ARE RESPONSIBLE FOR MAINTAINING THE SECURITY OF YOUR ACCOUNT AND
              PASSWORD. Congress.Cards WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
              FROM YOUR FAILURE TO MAINTAIN THE SECURITY OF YOUR ACCOUNT OR
              PASSWORD. YOU ARE RESPONSIBLE FOR ALL ACTIVITY THAT OCCURS UNDER
              YOUR ACCOUNT, INCLUDING ALL PURCHASES.
            </p>
            <p>
              ADDITIONALLY, TO THE EXTENT PERMITTED BY LAW, Congress.Cards
              EXCLUDES AND DISCLAIMS ALL IMPLIED WARRANTIES FOR PRODUCTS OFFERED
              THROUGH THIS SITE. Congress.Cards PROVIDES PRODUCTS ON AN “AS IS,”
              “AS AVAILABLE” BASIS.
            </p>
            <p>
              SOME STATES DO NOT PERMIT THE EXCLUSIONS OF CERTAIN TYPES OF
              LIABILITY OR LIMITATIONS ON CERTAIN TYPES OF WARRANTIES. IF THESE
              STATE LAWS APPLY TO YOU, SOME OF THE LIMITATIONS AND EXCLUSIONS
              ABOVE MAY NOT APPLY TO YOU, AND YOU MAY HAVE OTHER RIGHTS OR
              REMEDIES.
            </p>

            <h4>INDEMNIFICATION</h4>
            <p>
              You agree to indemnify and hold harmless Congress.Cards (and its
              members, directors, officers, employees, service providers,
              representatives, and agents) against all liabilities, losses,
              damages, and costs (including reasonable attorneys' fees) that
              they may incur based on claims arising out of your use of our Site
              or violation of these Terms. We reserve the right, at our own
              expense, to assume the exclusive defense and control of any matter
              for which we are entitled to indemnification. You agree to provide
              us with whatever cooperation we reasonably request.
            </p>

            <h4>CHANGES TO SITE, TERMINATION</h4>
            <p>
              We reserve the right to make changes or improvements to this Site
              at any time for any reason without notice.
            </p>

            <p>
              We reserve the right to terminate our Site and any services
              offered through our Site at any time for any reason with or
              without notice to you. The "Disclaimers," "Limitation of
              Liability,""Indemnification," and "General" sections of these
              Terms (along with any other provision that by its terms
              contemplates survival) survive any termination of these Terms.
            </p>

            <h4>COMMUNICATIONS</h4>
            <p>You consent to email communications from us.</p>

            <h4>GENERAL</h4>
            <p>
              These Terms, which includes the Privacy Policy, are the complete
              agreement between you and Congress.Cards regarding your use of our
              Site and are governed by the laws of the State of New York
              applicable to agreements made and completely performed in the
              State. You irrevocably agree to bring any claim or dispute
              relating to your use of our Site or these Terms exclusively in the
              state and federal courts located in the State and County of New
              York, to submit to the exclusive jurisdiction of those courts, and
              to waive any jurisdictional, venue, inconvenient forum, or other
              objections to those courts. If a court of competent jurisdiction
              determines that any provision of these Terms are unenforceable for
              any reason, then that provision will be deleted and the remaining
              provisions will be enforceable to the fullest extent permitted by
              law. In the event a court having jurisdiction over the parties
              holds any provision of this Agreement invalid or unenforceable,
              the parties shall negotiate in good faith to replace the invalid
              or unenforceable provision, if possible, with a valid provision
              that most closely approximates the intent and economic effect of
              the invalid provision. You agree that we may provide you with
              notices by posting on our site, by e-mail, or by regular mail. If
              we do not exercise or enforce a right or remedy in these Terms or
              otherwise available, you agree we have nor waived such right or
              remedy. The term "including" in these Terms means "including, but
              not limited to."
            </p>

            <h4>QUESTIONS</h4>
            <p>
              For any questions or comments you may have about these Terms,
              please contact us at: info@congress.cards
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
